import MainLayout from '@/Layouts/MainLayout';
import { Head, useRemember } from '@inertiajs/react';
import LetterList from '@/Components/LetterList';
import Paginator from '@/Components/Paginator';

export default function Welcome({ auth, letters }) {

    const [paginationData, setPaginationData] = useRemember(letters);

    return (
        <>
            <MainLayout auth={auth}>
                <Head title="letters to crushes" />
                <LetterList letters={letters} level="1" />
                <Paginator paginationData={paginationData} />
            </MainLayout>
        </>
    );
}
